<template>
  <div class="thirdsdk">
    <div class="privacy-table">
      <h2>个人信息第三方共享清单（含第三方sdk目录）</h2>
      <p>
        为保障游戏的相关功能的实现与应用安全稳定的运行，我们会接入由第三方提供的软件开发包（SDK）或类似应用程序实现相关目的。我们会对合作方获取的信息工具开放包（SDK）实施严格的安全检测，以保护您的个人信息安全。
      </p>
      <table>
        <thead>
          <tr>
            <th>第三方服务/插件</th>
            <th>机构/平台名称</th>
            <th>使用信息、场景及目的</th>
            <th>其他内容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>华为SDK (账户sdk、支付sdk、游戏内支付sdk)</td>
            <td>华为技术有限公司</td>
            <td>
              <p>
                使用场景：华为渠道玩家登录和使用游戏
                <br />
                使用信息：
                <br />
                运营商信息、日志信息、设备型号、手机制造商、游戏服务app版本、安卓系统版本号、Android
                SDK版本号、当前网络类型、oaid、fuid、WLAN连接、读取外置存储卡、写入外部存储卡、获取网络权限、INTERNET、Android
                ID、OAID、SSID、BSSID）；系统设置、系统属性、设备型号、设备品牌、操作系统、包体信息；IP
                地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 位置信息、Wi-Fi
                参数、Wi-Fi
                列表；、设备标识信息、OAID、AndroidID)、华为ID，网络状态信息(IP、当前网络类型和名称)
                <br />
                使用目的：
                <br />
                以上信息为了实现游戏以下功能：游戏初始化功能、游戏升级功能、游戏登录功能、游戏内公告功能、实名认证功能、浮标功能、防沉迷功能、成就功能、事件上报功能、排行榜功能、玩家信息统计功能、获取游戏基本信息功能
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人财产信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                获取网络状态权限、获取网络权限、判断当前前台应用的权限、展示悬浮窗的权限、获取已安装应用列表的权限
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                展示悬浮窗的权限：用于用户在游戏中展示浮标。
                <br />
                用获取已安装应用列表的权限：于检测用户是否在手机上安装了应用市场App和游戏中心App。
                <br />
                官方链接：
                <br />
                <a
                  href="https://developer.huawei.com/consumer/cn/doc/development/AppGallery-connect-Library/agc-auth-android-sdkdownload-0000001058498518"
                  >https://developer.huawei.com/consumer/cn/doc/development/AppGallery-connect-Library/agc-auth-android-sdkdownload-0000001058498518</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>OPPO SDK</td>
            <td>OPPO广东移动通信有限公司</td>
            <td>
              <p>
                使用场景：oppo渠道玩家登录和使用游戏
                <br />
                使用信息：
                <br />
                运营商信息、日志信息、设备型号、手机制造商、游戏服务app版本、安卓系统版本号、Android
                SDK版本号、当前网络类型、oaid、fuid、WLAN连接、读取外置存储卡、写入外部存储卡、获取网络权限、INTERNET、Android
                ID、OAID、SSID、BSSID）；系统设置、系统属性、设备型号、设备品牌、操作系统、包体信息；IP
                地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 位置信息、Wi-Fi
                参数、Wi-Fi
                列表；设备标识信息、OAID、AndroidID)，OPPOID，网络状态信息(IP、当前网络类型和名称)
                <br />
                使用目的：
                <br />
                以上信息为了实现游戏以下功能：账号注册、登录服务、实名认证服务、积分和会员服务、游戏福利和通知服务、游戏浮标功能、游戏版本更新服务、充值付费服务、游戏自助服务、网络连接、网络安全、产品优化、风险控制、分成结算
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人财产信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                获取网络状态权限、获取网络权限、判断当前前台应用的权限、展示悬浮窗的权限、获取已安装应用列表的权限
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                展示悬浮窗的权限：用于用户在游戏中展示浮标。
                <br />
                用获取已安装应用列表的权限：于检测用户是否在手机上安装了应用市场App和游戏中心App。
                <br />
                官方链接：
                <br />
                <a href="https://open.oppomobile.com/wiki/doc#id=10470"
                  >https://open.oppomobile.com/wiki/doc#id=10470</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>VIVO SDK</td>
            <td>维沃移动通信有限公司</td>
            <td>
              <p>
                使用场景：vivo渠道玩家登录和使用游戏
                <br />
                使用信息：
                <br />
                运营商信息、日志信息、设备型号、手机制造商、游戏服务app版本、安卓系统版本号、Android
                SDK版本号、当前网络类型、oaid、fuid、WLAN连接、读取外置存储卡、写入外部存储卡、获取网络权限、INTERNET、Android
                ID、OAID、SSID、BSSID）；系统设置、系统属性、设备型号、设备品牌、操作系统、包体信息；IP
                地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 位置信息、Wi-Fi
                参数、Wi-Fi
                列表；设备标识信息、OAID、AndroidID)，VIVOID，网络状态信息(IP、当前网络类型和名称)
                <br />
                使用目的：
                <br />
                以上信息为了实现游戏以下功能：账号服务、支付服务、实名认证、游戏福利
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人财产信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                获取网络状态权限、获取网络权限、判断当前前台应用的权限、展示悬浮窗的权限、获取已安装应用列表的权限
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                展示悬浮窗的权限：用于用户在游戏中展示浮标。
                <br />
                用获取已安装应用列表的权限：于检测用户是否在手机上安装了应用市场App和游戏中心App。
                <br />
                官方链接：
                <br />
                <a href="https://dev.vivo.com.cn/documentCenter/doc/712"
                  >https://dev.vivo.com.cn/documentCenter/doc/712</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>小米 SDK</td>
            <td>小米科技有限责任公司</td>
            <td>
              <p>
                使用场景：小米渠道玩家登录和使用游戏
                <br />
                使用信息：
                <br />
                运营商信息、日志信息、设备型号、手机制造商、游戏服务app版本、安卓系统版本号、Android
                SDK版本号、当前网络类型、oaid、fuid、WLAN连接、读取外置存储卡、写入外部存储卡、获取网络权限、INTERNET、Android
                ID、OAID、SSID、BSSID）；系统设置、系统属性、设备型号、设备品牌、操作系统、包体信息；IP
                地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 位置信息、Wi-Fi
                参数、Wi-Fi
                列表；设备标识信息、OAID、AndroidID)，小米ID，网络状态信息(IP、当前网络类型和名称)，传感器信息
                <br />
                使用目的：
                <br />
                以上信息为了实现游戏以下功能：账号服务、支付服务、实名认证、游戏福利
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人财产信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                获取网络状态权限、获取网络权限、判断当前前台应用的权限、展示悬浮窗的权限、获取已安装应用列表的权限
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                展示悬浮窗的权限：用于用户在游戏中展示浮标。
                <br />
                用获取已安装应用列表的权限：于检测用户是否在手机上安装了应用市场App和游戏中心App。
                <br />
                官方链接：
                <br />
                <a href="https://dev.mi.com/distribute/doc/details?pId=1402"
                  >https://dev.mi.com/distribute/doc/details?pId=1402</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>荣耀 SDK</td>
            <td>荣耀终端有限公司</td>
            <td>
              <p>
                使用场景：荣耀渠道玩家登录和使用游戏
                <br />
                使用信息：
                <br />
                运营商信息、日志信息、设备型号、手机制造商、游戏服务app版本、安卓系统版本号、Android
                SDK版本号、当前网络类型、oaid、fuid、WLAN连接、读取外置存储卡、写入外部存储卡、获取网络权限、INTERNET、Android
                ID、OAID、SSID、BSSID）；系统设置、系统属性、设备型号、设备品牌、操作系统、包体信息；IP
                地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 位置信息、Wi-Fi
                参数、Wi-Fi
                列表；设备标识信息、OAID、AndroidID)，荣耀ID，网络状态信息(IP、当前网络类型和名称)
                <br />
                使用目的：
                <br />
                以上信息为了实现游戏以下功能：账号服务、支付服务、实名认证、游戏福利
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人财产信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                获取网络状态权限、获取网络权限、判断当前前台应用的权限、展示悬浮窗的权限、获取已安装应用列表的权限
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                展示悬浮窗的权限：用于用户在游戏中展示浮标。
                <br />
                用获取已安装应用列表的权限：于检测用户是否在手机上安装了应用市场App和游戏中心App。
                <br />
                官方链接：
                <br />
                <a
                  href="https://developer.honor.com/cn/docs/game_center/guides/jieruzhinan/sdkyinsi"
                  >https://developer.honor.com/cn/docs/game_center/guides/jieruzhinan/sdkyinsi</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>百度SDK</td>
            <td>北京百度网讯科技有限公司</td>
            <td>
              <p>
                使用场景：百度渠道玩家登录和使用游戏
                <br />
                使用信息：
                <br />
                运营商信息、日志信息、设备型号、手机制造商、游戏服务app版本、安卓系统版本号、Android
                SDK版本号、当前网络类型、oaid、fuid、WLAN连接、读取外置存储卡、写入外部存储卡、获取网络权限、INTERNET、Android
                ID、OAID、SSID、BSSID）；系统设置、系统属性、设备型号、设备品牌、操作系统、包体信息；IP
                地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 位置信息、Wi-Fi
                参数、Wi-Fi
                列表；设备标识信息、OAID、AndroidID)，百度ID，网络状态信息(IP、当前网络类型和名称)
                <br />
                使用目的：
                <br />
                以上信息为了实现游戏以下功能：账号服务、支付服务、实名认证、游戏福利
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人财产信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                获取网络状态权限、获取网络权限、判断当前前台应用的权限、展示悬浮窗的权限、获取已安装应用列表的权限
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                展示悬浮窗的权限：用于用户在游戏中展示浮标。
                <br />
                用获取已安装应用列表的权限：于检测用户是否在手机上安装了应用市场App和游戏中心App。
                <br />
                官方链接：
                <br />
                <a href="https://g.baidu.com/gpbusiness/#/index"
                  >https://g.baidu.com/gpbusiness/#/index</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>BILIBILI渠道SDK</td>
            <td>哔哩哔哩股份有限公司</td>
            <td>
              <p>
                使用场景：BILIBILI渠道玩家登录和使用游戏
                <br />
                使用信息：
                <br />
                运营商信息、日志信息、设备型号、手机制造商、游戏服务app版本、安卓系统版本号、Android
                SDK版本号、当前网络类型、oaid、fuid、WLAN连接、读取外置存储卡、写入外部存储卡、获取网络权限、INTERNET、Android
                ID、OAID、SSID、BSSID）；系统设置、系统属性、设备型号、设备品牌、操作系统、包体信息；IP
                地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 位置信息、Wi-Fi
                参数、Wi-Fi
                列表；设备标识信息、OAID、AndroidID)，BILIBILIID，网络状态信息(IP、当前网络类型和名称)
                <br />
                使用目的：
                <br />
                以上信息为了实现游戏以下功能：账号服务、支付服务、实名认证、游戏福利
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人财产信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                获取网络状态权限、获取网络权限、判断当前前台应用的权限、展示悬浮窗的权限、获取已安装应用列表的权限
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                展示悬浮窗的权限：用于用户在游戏中展示浮标。
                <br />
                用获取已安装应用列表的权限：于检测用户是否在手机上安装了应用市场App和游戏中心App。
                <br />
                官方链接：
                <br />
                <a
                  href="https://open.biligame.com/docs/#/view-v2/1697151933908987904"
                  >https://open.biligame.com/docs/#/view-v2/1697151933908987904</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>抖音游戏联运SDK</td>
            <td>北京抖音科技有限公司、北京深极智能科技有限公司</td>
            <td>
              <p>
                使用场景：抖音渠道玩家登录和使用游戏
                <br />
                使用信息：
                <br />
                运营商信息、日志信息、设备型号、手机制造商、游戏服务app版本、安卓系统版本号、Android
                SDK版本号、当前网络类型、oaid、fuid、WLAN连接、读取外置存储卡、写入外部存储卡、获取网络权限、INTERNET、Android
                ID、OAID、SSID、BSSID）；系统设置、系统属性、设备型号、设备品牌、操作系统、包体信息；IP
                地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 位置信息、Wi-Fi
                参数、Wi-Fi
                列表；设备标识信息、OAID、AndroidID)，抖音ID，网络状态信息(IP、当前网络类型和名称)，设备传感器信息、网络信息、设备信息（手机型号/手机厂商/系统版本）
                <br />
                使用目的：
                <br />
                以上信息为了实现游戏以下功能：账号服务、支付服务、实名认证、游戏福利
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人财产信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                获取网络状态权限、获取网络权限、判断当前前台应用的权限、展示悬浮窗的权限、获取已安装应用列表的权限
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                展示悬浮窗的权限：用于用户在游戏中展示浮标。
                <br />
                用获取已安装应用列表的权限：于检测用户是否在手机上安装了应用市场App和游戏中心App。
                <br />
                官方链接：
                <br />
                <a
                  href="https://game.open.douyin.com/support/content/141403?graphId=537&mappingType=2&pageId=308&spaceId=182&timestamp=1722248091450"
                  >https://game.open.douyin.com/support/content/141403?graphId=537&mappingType=2&pageId=308&spaceId=182&timestamp=1722248091450</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>4399-SDK</td>
            <td>四三九九网络股份有限公司</td>
            <td>
              <p>
                使用场景：4399渠道玩家登录和使用游戏
                <br />
                使用信息：
                <br />
                运营商信息、日志信息、设备型号、手机制造商、游戏服务app版本、安卓系统版本号、Android
                SDK版本号、当前网络类型、oaid、fuid、WLAN连接、读取外置存储卡、写入外部存储卡、获取网络权限、INTERNET、Android
                ID、OAID、SSID、BSSID）；系统设置、系统属性、设备型号、设备品牌、操作系统、包体信息；IP
                地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 位置信息、Wi-Fi
                参数、Wi-Fi
                列表；设备标识信息、OAID、AndroidID)，4399ID，网络状态信息(IP、当前网络类型和名称)
                <br />
                使用目的：
                <br />
                以上信息为了实现游戏以下功能：账号服务、支付服务、实名认证、游戏福利
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人财产信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                获取网络状态权限、获取网络权限、判断当前前台应用的权限、展示悬浮窗的权限、获取已安装应用列表的权限
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                展示悬浮窗的权限：用于用户在游戏中展示浮标。
                <br />
                用获取已安装应用列表的权限：于检测用户是否在手机上安装了应用市场App和游戏中心App。
                <br />
                官方链接：
                <br />
                <a
                  href="https://ptlogin.4399.com/resource/protocol.html?type=2&aids=4,10"
                  >https://ptlogin.4399.com/resource/protocol.html?type=2&aids=4,10</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>233乐园SDK</td>
            <td>北京龙威互动科技有限公司</td>
            <td>
              <p>
                使用场景：233乐园渠道玩家登录和使用游戏
                <br />
                使用信息：
                <br />
                运营商信息、日志信息、设备型号、手机制造商、游戏服务app版本、安卓系统版本号、Android
                SDK版本号、当前网络类型、oaid、fuid、WLAN连接、读取外置存储卡、写入外部存储卡、获取网络权限、INTERNET、Android
                ID、OAID、SSID、BSSID）；系统设置、系统属性、设备型号、设备品牌、操作系统、包体信息；IP
                地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 位置信息、Wi-Fi
                参数、Wi-Fi
                列表；设备标识信息、OAID、AndroidID)，233乐园ID，网络状态信息(IP、当前网络类型和名称)
                <br />
                使用目的：
                <br />
                以上信息为了实现游戏以下功能：账号服务、支付服务、实名认证、游戏福利
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人财产信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                获取网络状态权限、获取网络权限、判断当前前台应用的权限、展示悬浮窗的权限、获取已安装应用列表的权限
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                展示悬浮窗的权限：用于用户在游戏中展示浮标。
                <br />
                用获取已安装应用列表的权限：于检测用户是否在手机上安装了应用市场App和游戏中心App。
                <br />
                官方链接：
                <br />
                <a
                  href="https://doc.233leyuan.com/protocol/%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.html#%E4%B8%83-%E6%9C%AA%E6%88%90%E5%B9%B4%E4%BA%BA%E6%9D%A1%E6%AC%BE"
                  >https://doc.233leyuan.com/protocol/%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.html#%E4%B8%83-%E6%9C%AA%E6%88%90%E5%B9%B4%E4%BA%BA%E6%9D%A1%E6%AC%BE</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>腾讯YSDK</td>
            <td>深圳市腾讯计算机系统有限公司</td>
            <td>
              <p>
                使用场景：应用宝渠道玩家登录和使用游戏
                <br />
                使用信息：
                <br />
                运营商信息、日志信息、设备型号、手机制造商、游戏服务app版本、安卓系统版本号、Android
                SDK版本号、当前网络类型、oaid、fuid、WLAN连接、读取外置存储卡、写入外部存储卡、获取网络权限、INTERNET、Android
                ID、OAID、SSID、BSSID）；系统设置、系统属性、设备型号、设备品牌、操作系统、包体信息；IP
                地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 位置信息、Wi-Fi
                参数、Wi-Fi
                列表；设备标识信息、OAID、AndroidID)，玩家ID，网络状态信息(IP、当前网络类型和名称)，设备传感器、OAID、应用安装列表、IP地址。
                <br />
                使用目的：
                <br />
                以上信息为了实现游戏以下功能：账号服务、支付服务、实名认证、游戏福利
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人财产信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                获取网络状态权限、获取网络权限、判断当前前台应用的权限、展示悬浮窗的权限、获取已安装应用列表的权限
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                展示悬浮窗的权限：用于用户在游戏中展示浮标。
                <br />
                用获取已安装应用列表的权限：于检测用户是否在手机上安装了应用市场App和游戏中心App。
                <br />
                官方链接：
                <br />
                <a href="https://game.qq.com/contract.shtml"
                  >https://game.qq.com/contract.shtml</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>手QSDK</td>
            <td>深圳市腾讯计算机系统有限公司</td>
            <td>
              <p>
                使用场景：使用qq登录、支付、分享
                <br />
                使用信息：
                <br />
                设备型号信息、剪切板信息、内存卡权限、特定应用（QQ、TIM）的安装情况
                <br />
                使用目的：
                <br />
                快速定位解决机型兼容性问题、实现第三方App分享能力、实现终端用户使用本地图片分享至QQ、QQ空间或进行头像设置、判断用户手机上是否安装QQ或TIM
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：个人常用设备信息
                <br />
                申请系统权限：无
                <br />
                申请目的：无
                <br />
                官方链接：<a href="https://wiki.connect.qq.com/"
                  >https://wiki.connect.qq.com/</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>微信SDK</td>
            <td>深圳市腾讯计算机系统有限公司</td>
            <td>
              <p>
                使用场景：使用微信注册、登录游戏、支付服务和分享功能
                <br />
                使用信息：
                <br />
                微信头像和昵称、微信支付订单、设备信息、应用信息、网络信息、运营商信息、剪切板信息、设备标识符、微信包名信息
                <br />
                使用目的：
                <br />
                提供账号登录、支付、分享
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                保存图片到相册权限、剪切板权限
                <br />
                申请目的：
                <br />
                实现分享功能
                <br />
                官方链接：
                <br />
                <a
                  href="https://pay.weixin.qq.com/wiki/doc/apiv3/wxpay/pages/index.shtml"
                  >https://pay.weixin.qq.com/wiki/doc/apiv3/wxpay/pages/index.shtml</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>支付宝sdk</td>
            <td>支付宝(中国)网络技术有限公司</td>
            <td>
              <p>
                使用场景：支付宝支付
                <br />
                使用信息：
                <br />
                指定包名信息 可变更的唯一设备识别码:OAID、SSID、BSSD、CAID
                基本设备信息:设备机型相关信息，通常用于功能适配、稳定性的功能，传感器信息，系统设置、系统属性、设备型号、设备品牌、操作系统、Android
                ID 网络信息: IP 地址、网络类型、运营商信息、 wiFi 状态、WiFi
                参数、 WiFi 列表
                <br />
                使用目的：
                <br />
                使用支付宝支付
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                位置、相机、存储权限
                <br />
                申请目的：
                <br />
                使用支付宝支付
                <br />
                官方链接：
                <br />
                <a href="https://opendocs.alipay.com/open/204"
                  >https://opendocs.alipay.com/open/204</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>支付宝h5支付SDK</td>
            <td>支付宝(中国)网络技术有限公司</td>
            <td>
              <p>
                使用场景：支付宝支付
                <br />
                使用信息：
                <br />
                指定包名信息 可变更的唯一设备识别码:OAID、SSID、BSSD、CAID
                基本设备信息:设备机型相关信息，通常用于功能适配、稳定性的功能，系统设置、系统属性、设备型号、设备品牌、操作系统、Android
                ID 网络信息: IP 地址、网络类型、运营商信息、 wiFi 状态、WiFi
                参数、 WiFi 列表
                <br />
                使用目的：
                <br />
                使用支付宝支付
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                位置、相机、存储权限
                <br />
                申请目的：
                <br />
                使用支付宝支付
                <br />
                官方链接：
                <br />
                <a href="http://myjsapi.alipay.com/jsapi/"
                  >http://myjsapi.alipay.com/jsapi/</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Quick云客服SDK</td>
            <td>成都惜东网络科技有限公司</td>
            <td>
              <p>
                使用场景：客服反馈问题
                <br />
                使用信息：
                <br />
                Android
                ID、网络信息、运营商信息、SDK版本号，屏幕尺寸，设备型号，手机操作系统版本号，App名称，App版本，时间戳，时区和网络
                <br />
                使用目的：
                <br />
                解决您在产品内遇到的问题、收集您的反馈或意见
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                使用摄像头拍摄、麦克风、相机、读取相册、保存相册
                <br />
                申请目的：
                <br />
                发送语音和图片反馈问题
                <br />
                官方链接：
                <br />
                <a href="https://www.quicksdk.com/base/agreenment/f/private"
                  >https://www.quicksdk.com/base/agreenment/f/private</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>极验SDK</td>
            <td>武汉极意网络科技有限公司</td>
            <td>
              <p>
                使用场景：手机号登录
                <br />
                使用信息：
                <br />
                设备信息：设备系统信息、设备厂商信息、设备型号、设备品牌、OAID、设备内存大小、设备系统语言的信息
                • 设备网络信息：IP、设备联网状态和类型的信息•
                设备环境信息：设备屏幕尺寸、设备电池充电状态、设备电量、设备调试标识、处理器、设备模拟器标识、设备代码篡改标识、浏览器UA、浏览器默认语言、网页请求
                referer 的信息
                <br />
                使用目的：
                <br />
                实现一键登录
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                申请目的：
                <br />
                官方链接：
                <br />
                <a href="https://www.geetest.com/">https://www.geetest.com/</a>
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>新浪微博SDK</td>
            <td>北京微梦创科网络技术有限公司</td>
            <td>
              <p>
                使用场景：使用微博分享功能
                <br />
                使用信息：
                <br />
                游戏id、昵称、应用内订单信息、设备标识符(UUID、ROM版本号、设备型号)、设备的硬件信息(设备名称)、应用信息、设备信息(操作系统、操作系统版本、系统语言、厂商、屏幕高度、屏幕宽度)、设备应用列表、系统日志、任务列表
                <br />
                使用目的：
                <br />
                提供分享服务
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人常用设备信息
                <br />
                申请系统权限：
                <br />
                保存图片到相册权限、剪切板权限
                <br />
                申请目的：
                <br />
                实现分享功能
                <br />
                官方链接：
                <br />
                <a
                  href="https://open.weibo.com/wiki/%E5%BC%80%E5%8F%91%E8%80%85%E5%8D%8F%E8%AE%AE#.E9.9A.90.E7.A7.81.E7.9B.B8.E5.85.B3.E6.9D.A1.E6.AC.BE"
                  >https://open.weibo.com/wiki/%E5%BC%80%E5%8F%91%E8%80%85%E5%8D%8F%E8%AE%AE</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>热力引擎SDK</td>
            <td>汇量云（北京）科技有限公司</td>
            <td>
              <p>
                使用场景：使用后台分析功能
                <br />
                使用信息：
                <br />
                游戏id、昵称、应用内订单信息、设备标识符(UUID、ROM版本号、设备型号)、设备的硬件信息(设备名称)、应用信息、设备信息(操作系统、操作系统版本、系统语言、厂商、屏幕高度、屏幕宽度)、设备应用列表、系统日志、任务列表、获取应用安装列表、Android
                ID、IMEI、读写外部存储
                <br />
                使用目的：
                <br />
                使用后台分析功能
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人常用设备信息
                <br />
                申请目的：
                <br />
                后台分析
                <br />
                官方链接：
                <br />
                <a href="https://help.solar-engine.com/cn/docs/C2aAyI"
                  >https://help.solar-engine.com/cn/docs/C2aAyI</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>穿山甲广告变现SDK</td>
            <td>北京巨量引擎网络技术有限公司</td>
            <td>
              <p>
                使用场景：使用广告变现功能
                <br />
                使用信息：
                <br />
                游戏id、昵称、应用内订单信息、设备标识符(UUID、ROM版本号、设备型号)、设备的硬件信息(设备名称)、应用信息、设备信息(操作系统、操作系统版本、系统语言、厂商、屏幕高度、屏幕宽度)、设备应用列表、系统日志、任务列表、获取应用安装列表、Android
                ID、IMEI、读写外部存储、设备传感器信息（加速度传感器、陀螺仪传感器、线性加速度传感器、磁场传感器、旋转矢量传感器）
                <br />
                使用目的：
                <br />
                使用广告播放功能
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人常用设备信息
                <br />
                申请目的：
                <br />
                广告展示
                <br />
                官方链接：
                <br />
                <a href="https://www.csjplatform.com/privacy/partner"
                  >https://www.csjplatform.com/privacy/partner</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Bugly异常信息收集SDK</td>
            <td>深圳市腾讯计算机系统有限公司</td>
            <td>
              <p>
                使用场景：收集用户崩溃信息
                <br />
                使用信息：
                <br />
                游戏id、昵称、应用内订单信息、设备标识符(UUID、ROM版本号、设备型号)、设备的硬件信息(设备名称)、应用信息、设备信息(操作系统、操作系统版本、系统语言、厂商、屏幕高度、屏幕宽度)、设备应用列表、系统日志、任务列表、获取应用安装列表、Android
                ID、IMEI、读写外部存储
                <br />
                使用目的：
                <br />
                收集用户崩溃信息
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人常用设备信息
                <br />
                申请目的：
                <br />
                收集用户崩溃信息
                <br />
                官方链接：
                <br />
                <a href="https://bugly.qq.com/v2/downloads"
                  >https://bugly.qq.com/v2/downloads</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>腾讯广告转化SDK</td>
            <td>深圳市腾讯计算机系统有限公司</td>
            <td>
              <p>
                使用场景：使用广告服务
                <br />
                使用信息：
                <br />
                游戏id、昵称、应用内订单信息、设备标识符(UUID、ROM版本号、设备型号)、设备的硬件信息(设备名称)、应用信息、设备信息(操作系统、操作系统版本、系统语言、厂商、屏幕高度、屏幕宽度)、设备应用列表、系统日志、任务列表、获取应用安装列表、Android
                ID、IMEI、读写外部存储
                <br />
                使用目的：
                <br />
                使用广告服务
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人常用设备信息
                <br />
                申请目的：
                <br />
                使用广告服务
                <br />
                官方链接：
                <br />
                <a href="https://game.qq.com/contract.shtml"
                  >https://game.qq.com/contract.shtml</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>巨量广告转化SDK</td>
            <td>北京市巨量引擎网络技术有限公司</td>
            <td>
              <p>
                使用场景：使用广告服务
                <br />
                使用信息：
                <br />
                游戏id、昵称、应用内订单信息、设备标识符(UUID、ROM版本号、设备型号)、设备的硬件信息(设备名称)、应用信息、设备信息(操作系统、操作系统版本、系统语言、厂商、屏幕高度、屏幕宽度)、设备应用列表、系统日志、任务列表、获取应用安装列表、Android
                ID、IMEI、读写外部存储
                <br />
                使用目的：
                <br />
                使用广告服务
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人常用设备信息
                <br />
                申请目的：
                <br />
                使用广告服务
                <br />
                官方链接：
                <br />
                <a href="https://open.oceanengine.com/register/protocol.html"
                  >https://open.oceanengine.com/register/protocol.html</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>七牛SDK</td>
            <td>上海七牛信息技术有限公司</td>
            <td>
              <p>
                使用场景：客服信息收集
                <br />
                使用信息：
                <br />
                网络类型、网络制式、Mac地址、ICCID、IMEI、IMSI、OAID
                <br />
                使用目的：
                <br />
                使用客服信息收集服务
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                网络类型、网络制式、Mac地址、ICCID、IMEI、IMSI、OAID
                <br />
                申请目的：
                <br />
                客服信息收集
                <br />
                官方链接：
                <br />
                <a href="https://developer.qiniu.com/qnvs/12514/privacy-right"
                  >https://developer.qiniu.com/qnvs/12514/privacy-right</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>热云SDK</td>
            <td>北京热云科技有限公司</td>
            <td>
              <p>
                使用场景：使用后台分析功能
                <br />
                使用信息：
                <br />
                游戏id、昵称、应用内订单信息、设备标识符(UUID、ROM版本号、设备型号)、设备的硬件信息(设备名称)、应用信息、设备信息(操作系统、操作系统版本、系统语言、厂商、屏幕高度、屏幕宽度)、设备应用列表、系统日志、任务列表、获取应用安装列表、Android
                ID、IMEI、读写外部存储
                <br />
                使用目的：
                <br />
                使用后台分析功能
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人常用设备信息
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                官方链接：
                <br />
                <a href="https://www.reyun.com/privacyPolicy/"
                  >https://www.reyun.com/privacyPolicy/</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>unity引擎</td>
            <td>Unity Technologies</td>
            <td>
              <p>
                使用场景：获取应用安装列表、Android ID、IMEI、读写外部存储
                <br />
                使用信息：
                <br />
                游戏id、昵称、应用内订单信息、设备标识符(UUID、ROM版本号、设备型号)、设备的硬件信息(设备名称)、应用信息、设备信息(操作系统、操作系统版本、系统语言、厂商、屏幕高度、屏幕宽度)、设备应用列表、系统日志、任务列表、获取应用安装列表、Android
                ID、IMEI、读写外部存储、传感器数据
                <br />
                使用目的：
                <br />
                获取应用安装列表、Android ID、IMEI、读写外部存储、传感器数据
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人常用设备信息
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                传感器数据：允许识别玩家设备朝向,根据玩家设备朝向改变app显示方向
                <br />
                官方链接：
                <br />
                <a href="https://unity3d.com/legal/privacy-policy"
                  >https://unity3d.com/legal/privacy-policy</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Unity3D插件</td>
            <td>Unity Technologies</td>
            <td>
              <p>
                使用场景：基础unity动画功能；app展示3d人物形象时，仅本地使用
                <br />
                使用信息：
                <br />
                网络状态、读取手机状态和身份、监听手机状态(否处于通话)、AndroidID、获取移动网络运营商名字、获取运行中进程信息权限、获取应用列表信息；传感器信息。
                <br />
                使用目的：
                <br />
                获取应用安装列表、Android ID、IMEI、读写外部存储、传感器数据
              </p>
            </td>
            <td>
              <p>
                信息向第三方共享：是
                <br />
                共享方式：sdk本机采集
                <br />
                信息类型：网络身份标识信息、个人基本信息、个人常用设备信息
                <br />
                申请目的：
                <br />
                获取网络状态权限：用于检测当前的网络连接是否有效。
                <br />
                获取网络权限：用于访问网络。
                <br />
                判断当前前台应用的权限：允许程序获取当前或最近运行任务的信息。
                <br />
                传感器数据：允许识别玩家设备朝向,根据玩家设备朝向改变app显示方向
                <br />
                官方链接：
                <br />
                <a href="https://unity3d.com/legal/privacy-policy"
                  >https://unity3d.com/legal/privacy-policy</a
                >
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>移动安全联盟MSA SDK</td>
            <td>电信终端产业协会移动安全工作委员会</td>
            <td>
              <p>
                SDK用途、功能：获取oaid
                <br />
                使用信息：
                <br />
                收集的个人信息类型：设备制造商、设备型号、设备品牌、设备网络运营商名称、APP包名及签名信息，或在对应应用商店的APPID
              </p>
            </td>
            <td>
              <p>
                官网链接：仅供参考：
                <a href="http://www.msa-alliance.cn/"
                  >http://www.msa-alliance.cn/</a
                >

                <br />
                隐私政策：仅供参考：<a
                  href="http://www.msa-alliance.cn/col.jsp?id=122"
                  >http://www.msa-alliance.cn/col.jsp?id=122</a
                >
                <br />
                处理方式：去标识化，加密传输的安全处理方式
              </p>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>鲸鸿动能SDK</td>
            <td>华为技术有限公司</td>
            <td>
              <p>
                SDK用途、功能：程序化广告投放、广告监测归因、广告防欺诈与运营维护。
                <br />
                使用信息：
                <br />
                收集范围：设备标识符（OAID、Android ID）、设备信息（设备型号、设备硬件信息、操作系统、系统设置、设备使用信息）、应用信息、传感器信息（陀螺仪、加速度计、旋转矢量传感器、磁力计、气压计）、网络信息、运营商信息、IP地址、Wi-Fi信息（Wi-Fi状态、Wi-Fi 参数与Wi-Fi列表）、位置信息（如精确位置信息、与Wi-Fi、蓝牙列表等粗略位置信息）、广告互动信息              </p>
            </td>
            <td>
              <p>
                官网链接：仅供参考：
                <a href="https://ads.huawei.com/usermgtportal/home/index.html#/"
                  >https://ads.huawei.com/usermgtportal/home/index.html#/</a
                >

                <br />
                隐私政策：仅供参考：<a
                  href="https://developer.huawei.com/consumer/cn/doc/HMSCore-Guides/whale-hong-kinetic-energy-sdk-privacy-statement-0000001658283582"
                  >https://developer.huawei.com/consumer/cn/doc/HMSCore-Guides/whale-hong-kinetic-energy-sdk-privacy-statement-0000001658283582</a
                >
                <br />
                处理方式：去标识化，加密传输的安全处理方式
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "ThirdSDK",
  data() {
    return {
      showTitle: false,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #3a79d4;
}

img {
  vertical-align: middle;
}

p {
  margin-bottom: 0;
}

html,
body {
  background-color: #f6f7fb;
  font-family: "Microsoft Yahei" !important;
}

body {
  display: none;
}

/* ::-webkit-scrollbar {
  display: none;
  } */
body {
  background-color: #fff;
  /* font-size: 1*2rem; */
}

.help {
  color: #e62631 !important;
}

.big_logo .bgi {
  width: 100%;
}

.doing-what {
  border-left: 0.125 * 2rem solid #3a79d4;
  font-size: 0.6875 * 2rem;
  padding-left: 0.6125 * 2rem;
  line-height: 0.6875 * 2rem;
  margin: 0.625 * 2rem 10.3125 * 2rem;
}

.help-content {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.help-content .help-box {
  margin: 1.25 * 2rem 0;
  overflow: hidden;
  width: 35.625 * 2rem;
  background-color: #fff;
  padding: 0.8125 * 2rem;
  box-sizing: border-box;
  border-radius: 0.1875 * 2rem;
  border: 0.0625 * 2rem solid #eee;
}

.help-content .help-box li {
  background-color: #f5f5f5;
  border-radius: 0.1875 * 2rem;
  font-size: 0.5625 * 2rem;
  margin-bottom: 0.625 * 2rem;
}

.help-content .help-box li .top {
  width: 33.6875 * 2rem;
  height: 2.0625 * 2rem;
  padding: 0 1.25 * 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.help-content .help-box li:last-of-type {
  margin-bottom: 0;
}

.help-content .help-box li .jiantou {
  width: 0.3125 * 2rem;
  height: 0.3125 * 2rem;
  border-left: 0.0625 * 2rem solid #ccc;
  border-bottom: 0.0625 * 2rem solid #ccc;
  transform: rotate(-45deg);
}

.help-content .help-box li:last-of-type .jiantou {
  transform: rotate(-45deg);
}

.help-content .help-box li .problem {
  padding: 0 1.25 * 2rem 0.625 * 2rem 1.25 * 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.help-content .help-box li .problem .topic {
  color: red;
  margin-top: 0.625 * 2rem;
}

.help-content .help-box li .problem .content {
  padding: 0.625 * 2rem;
  margin-top: 0.625 * 2rem;
  background-color: #f5f5f5;
  border-radius: 0.1875 * 2rem;
  border: 0.0325 * 2rem solid #e7e7e7;
  font-size: 0.4375 * 2rem;
  display: flex;
  flex-direction: column;
  vertical-align: top;
}

.help-content .help-box li .problem .content span:not(:last-of-type) {
  height: 1.25 * 2rem;
}

.help-content .help-box li .bottom {
  border-top: 0.0325 * 2rem solid #eee;
  display: block;
}

.problem p {
  cursor: pointer;
}

.problem .bottomic {
  display: none;
}

.p_con,
.p_join {
  line-height: 1.25 * 2rem;
  font-size: 0.5 * 2rem;
  text-align: center;
}

.p_content .p_title {
  font-size: 0.5587 * 2rem;
  font-weight: 700;
  line-height: 1.5 * 2rem;
}

.p_content .p_line {
  font-size: 1 * 2rem;
  width: 0.625 * 2rem;
  color: #c4222e;
}

.p_content .p_icon {
  text-indent: 2em;
  line-height: 1.2 * 2rem;
  font-size: 0.4375 * 2rem;
}

.p_content .min_title {
  font-size: 0.4688 * 2rem;
  font-weight: 700;
}

.left {
  float: left;
}

.media {
  margin-left: 3.125 * 2rem;
  margin-top: 1.25 * 2rem;
}

.p_content img {
  width: 100%;
}

// 自动换行
.p_content a {
  word-break: break-all;
  /* 或者使用 word-wrap: break-word; */
}

.privacy-table {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.privacy-table h2 {
  text-align: center;
  margin-bottom: 20px;
}

.privacy-table p {
  margin-bottom: 20px;
  line-height: 1.5;
}

.privacy-table table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.privacy-table th,
.privacy-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.privacy-table th {
  background-color: #f4f4f4;
}

.privacy-table ul {
  margin: 0;
  padding-left: 20px;
}

.privacy-table li {
  list-style-type: disc;
}
</style>
